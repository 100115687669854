<template>
  <div class="content-wrap">
    <div class="content-tree">
      <div class="tree-cont">
        <div class="item-title">
          <span>组织列表</span>
          <div>
            <el-input v-model="orgName" placeholder="请输入组织名称" />
            <i class="el-icon-search"></i>
            <el-button class="cbtn" type="primary" @click="handleQueryOrg">查询</el-button>
          </div>
        </div>
        <el-tree class="tree-body" :data="treeData" v-loading="treeLoading" :props="treeProps" :default-expanded-keys="[SecondaryNode]" node-key="id" @node-click="handleNodeClick"> </el-tree>
      </div>
    </div>
    <div class="content-table">
      <el-form class="table-head" :model="filter" :inline="true">
        <div class="head-item">
          <el-form-item label="账号/姓名">
            <el-input v-model.trim="filter.keyword" placeholder="关键字..." maxlength="15" @keyup.enter.native="submitForm()"></el-input>
          </el-form-item>
          <el-button type="primary" :disabled="listLoading" @click="handleReset">重置 </el-button>
          <el-button type="primary" :disabled="listLoading" @click="handleQuery">查询 </el-button>
        </div>
        <div class="head-item">
          <div style="display: flex">
            <div class="action" v-role="'RS-M02-F01'" @click="handleCreateUser">
              <img src="@/assets/icon/user-manage/icon-add.png" />
              <span>新增用户</span>
            </div>
            <div class="action" @click="importVisible = true" style="margin-left: 10px">
              <span>导入用户</span>
            </div>
          </div>
        </div>
      </el-form>
      <div class="table-view">
        <el-table :data="listData" v-loading="listLoading" stripe border :header-cell-style="handleHeaderCellStyle" @cell-mouse-enter="handleCellEnter" @cell-mouse-leave="handleCellLeave" style="width: 100%; overflow-y: auto">
          <el-table-column type="index" label="序号" width="50"> </el-table-column>
          <el-table-column prop="nickName" label="姓名" min-width="100" show-overflow-tooltip> </el-table-column>
          <el-table-column prop="username" label="账号" width="120"> </el-table-column>
          <el-table-column prop="phone" label="手机号" width="120"> </el-table-column>
          <el-table-column prop="orgName" label="所属组织" min-width="180" show-overflow-tooltip> </el-table-column>
          <el-table-column prop="enabled" label="状态" width="80">
            <template slot-scope="scope">
              <el-switch v-model="scope.row.enabled" @change="handleEnabledChange(scope.row)" active-color="#1890FF" inactive-color="#F5222D"> </el-switch>
            </template>
          </el-table-column>
          <el-table-column prop="locked" label="锁定" width="80">
            <template slot-scope="scope">
              <el-switch v-model="scope.row.locked" @change="handleLockedChange(scope.row)" active-color="#1890FF" inactive-color="#F5222D"> </el-switch>
            </template>
          </el-table-column>
          <el-table-column prop="operate" fixed="right" label="操作" width="150">
            <template slot-scope="scope">
              <el-button :disabled="isManager" v-role="'RS-M02-F02'" type="text" size="medium" style="width: 40px; padding: 0" @click="handleUpdateUser(scope.row)">编辑 </el-button>
              <el-button :disabled="isManager || scope.row.isSystematic" v-role="'RS-M02-F03'" type="text" size="medium" style="width: 40px; padding: 0" @click="handleDeleteUser(scope.row)">删除 </el-button>
            </template>
          </el-table-column>
        </el-table>
        <div class="table-page">
          <el-pagination background @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page.sync="paging.current" :page-sizes="pageSizes" :page-size="paging.size" layout="total, sizes, prev, pager, next" :total="paging.total"> </el-pagination>
        </div>
      </div>
    </div>
    <el-dialog append-to-body :title="detailTitle" :close-on-click-modal="false" :close-on-press-escape="true" :show-close="false" :visible.sync="detailVisible" width="600px">
      <el-form :model="detailModel" :rules="detailModelRules" ref="detailModelForm" v-loading="detailLoading" style="padding-bottom: 60px" label-position="top">
        <div class="edit-select">
          <el-row class="edit-select-item">
            <el-col :span="12">
              <el-form-item prop="nickName" required label="姓名">
                <el-input v-model="detailModel.nickName" placeholder="请输入姓名"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item prop="orgId" required label="组织">
                <organization-select :filterable="true" all-label="请选择" :is-add-all="false" :model="detailModel"></organization-select>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row class="edit-select-item">
            <el-col :span="12">
              <el-form-item prop="username" required label="账号">
                <el-input v-model="detailModel.username" placeholder="请输入账号"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12" style="margin-bottom: 20px">
              <el-form-item prop="password" label="登录密码">
                <el-input type="password" v-model="detailModel.password" placeholder="请输入密码"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row class="edit-select-item">
            <el-col :span="12">
              <el-form-item prop="phone" label="手机号码">
                <el-input v-model="detailModel.phone" type="tel" placeholder="请输入手机号码"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item prop="roles" required label="岗位">
                <el-select v-loading="roleLoading" v-model="detailModel.roles" multiple filterable collapse-tags placeholder="请选择">
                  <el-option v-for="item in roleData" :key="item.roleId" :label="item.roleName" :value="item.roleId"> </el-option>
                </el-select>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row class="edit-select-item">
            <el-col :span="12">
              <el-form-item label="状态" style="width: 200px">
                <el-radio v-model="detailModel.enabled" :label="true">启用</el-radio>
                <el-radio v-model="detailModel.enabled" :label="false">禁用</el-radio>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item prop="dataId" label="用户编码">
                <el-input v-model="detailModel.dataId" placeholder="用户编码"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row class="edit-select-item">
            <el-col :span="24">
              <el-form-item label="备注信息">
                <el-input type="textarea" v-model="detailModel.desc" maxlength="120" style="width: 80%; margin-top: 10px" placeholder="备注信息"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
        </div>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="handleCancelSave">取 消</el-button>
        <el-button type="primary" @click="handleSaveUser">确 定</el-button>
      </div>
    </el-dialog>

    <el-dialog append-to-body title="导入用户" :visible.sync="importVisible" width="500px" :show-close="false">
      <el-form class="income-wrap" ref="merchandiseFileRef" :model="userFile" v-loading="uploadLoading">
        <div class="edit-select">
          <div style="margin-top: -40px">
            <a :href="staticFile.userImportXls.url" :download="staticFile.userImportXls.name">{{ staticFile.userImportXls.name }}</a>
          </div>
          <div class="edit-select-item">
            <el-input v-model="userFile.fileName" placeholder="文件名称.xlsx 或 文件名称.xls" style="width: 300px"></el-input>
            <el-upload accept="application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" action="#" :multiple="false" :show-file-list="false" :on-exceed="handleExceed" :on-change="uploadFile">
              <el-button type="default" icon="el-icon-upload2"> 上传文件 </el-button>
            </el-upload>
          </div>
        </div>
        <div class="edit-select" style="margin-top: 10px; padding: 0 20px 0 20px">
          <div class="edit-select-foot">
            <el-button class="el-button-ext" @click="uploadClose">取消</el-button>
            <el-button class="el-button-ext" @click="uploadAccount" type="primary">导入 </el-button>
          </div>
        </div>
      </el-form>
    </el-dialog>
  </div>
</template>

<script>
import RegexLib from '@/assets/js/regex-lib';
import OrganizationSelect from '@/components/widgets/OrganizationSelect';
import { tableStyle, pagingMethods } from '@/util/mixins';
import { ReqUserGetListByPage, ReqUserGetUser, ReqUserSaveUser, ReqUserDelUser, ReqUserSetEnabled, ReqUserSetLocked, uploadUser } from '@/service/user';
import { ReqRoleGetRoles, updateRoles } from '@/service/role';
import { ReqOrganizationGetOrgTreeInScope, ReqOrganizationTree } from '@/service/organization';
import StaticFile from '@/assets/js/static-file';

export default {
  name: 'User',
  mixins: [tableStyle, pagingMethods],
  components: { OrganizationSelect },
  data() {
    //组织格式验证
    const validateOrgId = (rule, value, callback) => {
      if ('全部' == this.detailModel.orgId) {
        callback(new Error('请选择组织'));
      } else {
        callback();
      }
    };
    return {
      staticFile: StaticFile,
      filter: {
        keyword: ''
      },
      listLoading: false,
      treeLoading: false,
      paging: {
        current: 1,
        size: 15,
        total: 0,
        searchCount: true,
        pages: 1
      },
      isManager: false,
      pageSizes: [15, 30, 50, 100],
      treeData: [],
      treeProps: {
        children: 'children',
        label: 'name'
      },
      listData: [],
      roleData: [],
      roleLoading: false,
      detailVisible: false,
      detailModel: {
        id: 0,
        orgId: undefined,
        username: undefined,
        nickName: undefined,
        password: undefined,
        phone: undefined,
        enabled: true,
        roles: [],
        desc: undefined,
        isSystematic: false
      },
      detailModelRules: {
        orgId: [
          { required: true, message: '请选择所属组织' },
          { validator: validateOrgId, trigger: 'blur' }
        ],
        username: [
          { required: true, message: '请输入用户账号' },
          { pattern: RegexLib.Username, message: '须是4~20位字母、数字、-_@.', trigger: 'blur' }
        ],
        nickName: [
          { required: true, message: '请输入用户姓名' },
          { min: 2, max: 8, message: '长度为 2~8 个字符', trigger: 'blur' }
        ],
        phone: [{ pattern: RegexLib.Phone, message: '手机号输入有误', trigger: 'blur' }],
        roles: [{ required: true, message: '请选择用户岗位' }]
      },
      detailLoading: false,
      detailTitle: '新增用户',
      SecondaryNode: '',
      orgName: '',
      uploadLoading: false,
      userFile: {
        fileName: '',
        file: {}
      },
      importVisible: false
    };
  },
  created() {
    this.getTree();
    this.submitForm();
    this.getRole();
  },
  methods: {
    uploadClose() {
      this.importVisible = false;
      this.userFile.file = null;
      this.userFile.fileName = '';
    },
    /* 导入保存 */
    async uploadAccount() {
      if (!this.userFile.file.name) {
        this.toast('请选择要上传文件！', 'warning');
        return;
      }
      this.confirmImport();
    },
    //处理确认
    async confirmImport() {
      let formData = new FormData();
      formData.append('file', this.userFile.file);

      this.uploadLoading = true;
      const res = await uploadUser(formData);
      this.uploadLoading = false;
      if (res.success) {
        this.toast('导入成功！', 'success');
        this.handleQuery();
      }
      this.userFile.file = null;
      this.userFile.fileName = '';
    },
    /* 上传错误捕获 */
    handleExceed() {
      this.toast('上传文件出错', 'error');
    },
    uploadFile(file) {
      this.userFile.fileName = file.name;
      this.userFile.file = file.raw;
    },
    async handleQueryOrg() {
      this.treeLoading = true;
      const { success, data } = await ReqOrganizationTree({ name: this.orgName });
      this.treeLoading = false;
      if (success) {
        this.treeData = data;
        if (data.length > 1) {
          this.SecondaryNode = this.treeData[0].id;
        }
      }
    },
    handleQuery() {
      this.paging.current = 1;
      this.paging.searchCount = true;
      this.submitForm();
    },
    handleReset() {
      this.filter.keyword = '';
    },
    //切换分页条数
    handleSizeChange(value) {
      this.paging.size = value;
      this.submitForm();
    },
    //翻页
    handleCurrentChange(value) {
      this.paging.current = value;
      this.submitForm();
    },
    /* 设置启用状态 */
    handleEnabledChange(row) {
      ReqUserSetEnabled({ userId: row.id, enabled: row.enabled }).then((res) => {
        this.toast(res.message, 'success');
      });
    },
    /* 设置锁定状态 */
    handleLockedChange(row) {
      ReqUserSetLocked({ userId: row.id, locked: row.locked }).then((res) => {
        this.toast(res.message, 'success');
      });
    },
    handleNodeClick(node) {
      this.filter.orgId = node.id;
      this.paging.current = 1;
      this.paging.searchCount = true;
      this.submitForm();
    },
    handleSaveUser() {
      this.$refs.detailModelForm.validate((valid) => {
        if (!valid) return;
        this.saveUser();
      });
    },
    handleCancelSave() {
      this.detailVisible = false;
      this.$refs.detailModelForm.resetFields();
    },
    handleCreateUser() {
      this.detailTitle = '新增用户';
      this.detailModel = {
        id: 0,
        orgId: undefined,
        username: undefined,
        nickName: undefined,
        password: undefined,
        enabled: true,
        phone: undefined,
        isSystematic: false,
        roles: [],
        desc: undefined,
        isLabour: 'N'
      };
      this.detailModelRules.password = [
        { required: true, message: '请输入用户密码' },
        { pattern: "^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%^&+=,./?;'])(?=\\S+$).{10,20}$", message: '须是 10~20 位至少要包含数字、大小写字母、特殊字符（!@#$%^&+=）', trigger: 'blur' }
      ];
      this.detailVisible = true;
    },
    handleUpdateUser(row) {
      this.detailTitle = '修改用户';
      this.detailModelRules.password = [{ pattern: "^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%^&+=,./?;'])(?=\\S+$).{10,20}$", message: '须是 10~20 位至少要包含数字、大小写字母、特殊字符（!@#$%^&+=）', trigger: 'blur' }];
      this.detailVisible = true;
      this.getUser(row.id);
    },
    handleDeleteUser(row) {
      console.log(row);
      this.$confirm('您确定要删除该用户吗？', '', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          this.delUser(row.id);
        })
        .catch(() => {});
    },
    saveUser() {
      this.detailLoading = true;
      ReqUserSaveUser(this.detailModel).then((res) => {
        if (res.success) {
          this.toast('保存用户信息成功', 'success', () => {
            this.detailVisible = false;
            this.$refs.detailModelForm.resetFields();
            this.submitForm();
          });
        }
        this.detailLoading = false;
      });
    },
    getUser(userId) {
      this.detailLoading = true;
      ReqUserGetUser({ userId: userId }).then((res) => {
        if (res.success) {
          this.detailModel = res.data;
          //判断当前租户下是否存在对应组织，不存在设置为null;
          let organizationStr = localStorage.getItem('organizations');
          let organizationArr = JSON.parse(organizationStr);
          let orgIdArr = [];
          for (let i in organizationArr) {
            orgIdArr.push(organizationArr[i].id);
          }
          let result = orgIdArr.find((item) => item == this.detailModel.orgId);
          if (result == null) {
            this.detailModel.orgId = null;
          }
        }
        this.detailLoading = false;
      });
    },
    delUser(userId) {
      ReqUserDelUser({ userId: userId }).then((res) => {
        if (res.success) {
          this.toast('删除用户信息成功', 'success', () => this.submitForm());
        }
      });
    },
    submitForm() {
      this.listLoading = true;
      ReqUserGetListByPage({ filter: this.filter, paging: this.paging }).then((res) => {
        if (res.success) {
          let list = res.data.records;
          this.listData = list;
          delete res.data.records;
          this.paging = Object.assign(this.paging, res.data, {
            searchCount: false
          });
        }
        this.listLoading = false;
      });
    },
    async getTree() {
      this.treeLoading = true;
      const { success, data } = await ReqOrganizationGetOrgTreeInScope();
      this.treeLoading = false;
      if (success && data.length > 0) {
        this.treeData = data;
        this.SecondaryNode = this.treeData[0].id;
      }
    },
    getRole() {
      this.roleLoading = true;
      ReqRoleGetRoles().then((res) => {
        if (res.success || res.code == 0) {
          this.roleData = res.data;
        }
        this.roleLoading = false;
      });
    }
  }
};
</script>

<style scoped lang="scss">
.edit-select {
  .edit-select-item {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 0 20px 0 20px;
  }

  .edit-select-item:first-child {
    margin-bottom: 50px;
  }

  .edit-select-item:last-child {
    margin-top: 20px;
  }

  .edit-select-foot {
    display: flex;
    height: 60px;
    width: 100%;
    justify-content: flex-end;
  }
}
.content-wrap {
  display: flex;
  flex-direction: row;

  .content-tree {
    width: 30%;
    min-width: 200px;
    height: 100%;
    padding-right: 20px;

    .tree-cont {
      background-color: #ffffff;
      box-shadow: 0px 2px 8px 0px rgba(153, 153, 153, 0.1);
      height: 100%;
      overflow-y: auto;

      .tree-body {
        padding: 20px 10px;
      }

      .el-tree-node__content {
        height: 30px;
      }

      .item-title {
        display: flex;
        flex-direction: column;
        padding: 16px 24px;
        border-bottom: 1px solid #e5e5e5;

        span {
          color: #333333;
          font-size: 16px;
          font-weight: 500;
          margin-bottom: 12px;
        }

        span:before {
          content: '|';
          margin-right: 10px;
          font-weight: 500;
          background-color: #333333;
        }

        div {
          position: relative;
          vertical-align: middle;

          .cbtn {
            margin-left: 16px;
          }

          i {
            position: absolute;
            left: 180px;
            margin-top: 8px;
          }
        }
      }
    }
  }

  .content-table {
    background-color: #ffffff;
    width: 70%;
    height: 100%;
    box-shadow: 0px 2px 8px 0px rgba(153, 153, 153, 0.1);

    .table-head {
      height: 80px;
      display: flex;
      align-items: center;
      flex-direction: row;
      justify-content: space-between;
      padding: 0 20px;

      .head-item {
        display: flex;
      }

      .action {
        span {
          color: #3d94ff;
          font-weight: 500;
          font-size: 16px;
        }

        img {
          width: 20px;
          height: 20px;
          vertical-align: sub;
          margin-right: 4px;
        }
      }

      .action:hover {
        text-decoration: underline;
        cursor: pointer;
      }
    }

    .table-view {
      max-height: calc(100% - 80px);
      overflow-y: auto;
      flex-direction: column;
      justify-content: space-between;
      padding: 0 20px;
    }

    .table-page {
      display: flex;
      height: 50px;
      align-items: center;
      justify-content: center;
    }
  }
}

.edit-select {
  display: flex;
  padding: 0 30px;
  flex-direction: column;
  /*width: 100px;*/

  .edit-select-item {
    text-align: initial;
    margin-bottom: 24px;
    display: flex;
    flex-direction: row;
    justify-content: space-around;

    ::v-deep .el-select__tags-text {
      display: inline-block;
      //根据自己的需要调整文字宽度
      max-width: 65px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    //根据自己需要调整叉号的位置
    ::v-deep .el-tag__close.el-icon-close {
      top: -6px;
      right: -6px;
    }
  }

  .edit-select-item-1 {
    text-align: initial;
    margin-bottom: 24px;
    display: flex;
    justify-content: flex-start;
  }

  .edit-select-foot {
    display: flex;
    width: 100%;
    justify-content: flex-end;
  }
}

.data-view {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: calc(100% - 80px);
}
</style>
